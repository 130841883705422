.login-container {
  // height: 100%;
  width: 101%;
  // background: url("./../assets/Flow\ 57@512p-25fps.gif") no-repeat;
  // background-size: cover;
  // background-position: center;
  .login-inner-container {
    .login-middle-section {
      .form-group {
        border-bottom: 1px solid #3333;
      }
    }
  }
}
.bgimage{
  object-fit: contain;
  width: 100%;
  height: 27vh;
  object-position: center;
}
@media screen and (max-width: 767px) {
  .bgimage {
    height: 40vh;
  }
}
.profile-container {
  padding: 1rem;
  .profile-inner-container {
    .edit-profile-form {
      .form-action {
        .btn-common-primary {
          color: #000000;
          @media (max-width: 991.98px) {
            position: fixed;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            right: 0;
            width: 95% !important;
            text-align: center;
          }
        }
      }
    }
  }
}

.my-order-container {
  .my-order-inner {
    .my-order-list-container {
      .order-tab-container {
        .tab-btn {
          width: 15%;
          &.active {
            background: rgb(5, 201, 171);
            background: linear-gradient(
              180deg,
              rgba(5, 201, 171, 1) 0%,
              rgba(2, 54, 46, 1) 100%
            );
            color: $white;
            transform: scale(1.1);
          }
        }
        @media screen and (max-width: 767px) {
          .tab-btn {
            width: 40%;
          }
        }
      }

      .my-order-list {
        .order-card-main {
          box-shadow: 1px 1px 1px 1px rgb(214 214 214 / 8%);
          .order-card-top {
            .order-id-span {
            }
          }
        }
      }
    }
  }
}

.sidebar-main-content {
  min-height: 84vh;
  .profile-menu-container {
    .profile-inner-menu {
      .profile-ul-container {
        ul {
          list-style: none;
          li {
            .profile-nav-cta {
              div {
                img {
                  // background: #d9d9d9;
                  height: 30px;
                  width: 30px;
                  padding: 5px;
                }
              }
            }
            padding: 0.6rem 0;
            &:not(:last-child) {
              border-bottom: 1px solid #d9d9d9cc;
            }

            &.active {
              .profile-nav-cta {
                div {
                  img {
                    background: var(--primaryYellow);
                  }
                }
              }
            }
          }
        }
      }
    }

    .profile-menu-bottom {
      .logout-container {
        margin-bottom: 0.5rem;
        .btn-logout {
          padding: 7px 15px;
          border: none;
          background: var(--white);
          color: var(--red);
          border-radius: 8px;
          border: 1px solid #00000040;
        }
      }
    }
  }
}

.order-detail-container {
  .order-detail-inner {
    &.track-order-inner {
      height: calc(100vh - 85px);
    }
    .vendor-locations {
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
          .pickup-flow {
            display: flex;
            &.vendor-address {
              .pickup-icon {
                img {
                  color: #f7bd03;
                }
              }
            }
            .pickup-icon {
              position: relative;
              img {
                height: 35px;
              }
            }
          }
          &:not(:last-child) {
            .pickup-flow {
              margin-bottom: 1rem;
              .pickup-icon {
                &::before {
                  content: "";
                  position: absolute;
                  height: calc(100% - 22px);
                  border-right: 1px dashed #a9abb2;
                  top: 35px;
                  left: 50%;
                  transform: translateX(-50%);
                }
              }
            }
          }
        }
      }
    }

    .order-items-list {
      .order-item-detail {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }

    .order-cost-list {
      ul {
        list-style: none;
        li {
          .cost-titl {
            color: #ababab;
          }

          &.order-total {
            padding-bottom: 0.5rem;
            .cost-titl {
              color: $black;
              font-size: 125%;
            }

            .cost-pric {
              font-size: 125%;
              font-weight: 500;
            }
          }
        }
      }
    }

    .order-track-middle {
      img {
        height: 200px;
      }

      .cus-code {
        background: #263238;
        color: $white;
        border-radius: 6px;
        padding: 3px;
        position: absolute;
        bottom: 15px;
        text-align: center;
        right: 5px;
        .code-titl {
          font-size: 0.8rem;
        }
        .code-digit {
          letter-spacing: 5px;
          font-weight: 500;
        }
      }
    }
  }
}
