@import '../styles/shared/variables';

body {
  background-color: var(--black);

}

.main-container {
  margin: 5rem 0 0 0;
}
@media screen and (max-width: 967px) {
  .main-container {
  margin: 5rem 0 5rem 0!important;
}
}

.rounded_img {
    border-radius: 0.5rem;
}

.hp-100 {
  height: 100px;
  @media (min-width: 768px) {
    height: 120px;
  }
}

.top_rounded_img {
  border-radius: 0.5rem 0.5rem 0 0;
}

.min-vh-30 {
    min-height: 30vh;
  }

.border-rounded-top {
    border-radius: 0.5em 0.5em 0 0;
  }

.border-rounded{
  border-radius: 1rem;
}


.aspect_9_16 {
  aspect-ratio: 9/16;
}


.aspect_2_3 {
  aspect-ratio: 2/2.25;
}

.aspect_1_1 {
  aspect-ratio: 1/1;
}

app-fak-thumbnail {
  &.swiper_cover {
    height: 100%;
  }
}

.swiper_img {
  width: 100%;
  height: 100%;
  aspect-ratio: 2/2.5;
  object-position: top;
  border-radius: 5px 5px 0px 0px;
}
.objPosTop{
  object-position: top;
}
@media screen and (max-width:796px) {
  .swiper_img {
    min-height: 100%;
  }
}

.btn-common-primary{
  background-color: var(--primaryYellow);
  border-radius: 4px;
}
.bgBlack{
  background-color: var(--black);
}
.bgDarkGrey{
  background-color: var(--darkGrey)!important;
}
@media screen and (max-width:1024px) {
  .bgGrey{
    background-color: var(--darkGrey)!important;
  }
}
.yellow-circle {
  background: #F9AE06;
  height: 18px;
  display: block;
  width: 18px;
  border-radius: 50%;
  box-shadow: 1px 1px 6px 1px #F7BD0382;
}