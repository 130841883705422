.cart-items-container {
    .cart-items-inner-container {
        .cart-order-summary-container {
            .proceed-to-payment-btn {
                @media (min-width: 1099px){
                    position: relative;
                }
            }
        }
    }
}