@import "./styles/base/base";
@import "./styles/shared/shared";

@import "./styles/account";
@import "./styles/common";
@import "./styles/feed";
@import "./styles/layout";
@import "./styles/swiper";
@import "./styles/product";
@import "./styles/checkout";

html,
body {
}
* {
  font-family: "Poppins-Regular";
}
body {
  margin: 0;
  // background-color: var(--black);
//   background: rgb(192,130,130);
background: radial-gradient(circle, #444444 0%, rgba(0,0,0,1) 100%);
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("./assets/Poppins/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("./assets/Poppins/Poppins-Bold.ttf") format("truetype");
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0;
}
.poppins {
  font-family: "Poppins-Regular";
}

.fs-9 {
  font-size: 10px;
}

.fs-8 {
  font-size: 12px;
}
