@import "./variables";

.fw-500 {
  font-weight: 500;
}
.primaryYellow {
  color: var(--primaryYellow);
}
.bgDarkGrey {
  background: var(--darkGrey);
}
.primaryGreen {
  color: var(--primaryGreen);
}
.successGreen {
  color: var(--greenSuccessColor);
}
.greenHover:hover {
  color: var(--primaryGreen);
}
.borderHover:hover {
  border: 1px solid var(--primaryGreen) !important;
}
.secondaryGrey {
  color: var(--grey);
}
.sbwnone {
  scrollbar-width: none;
}
.greenborder {
  border: 1px solid var(--primaryGreen);
}
.accountRightNav {
  height: 84vh;
  overflow: scroll;
  scrollbar-width: none;
}
.bgprimaryGreen {
  background-color: var(--primaryGreen);
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
  font-size: 0.7rem;
  text-align: center;
}
.h-lg-75 {
  height: 75%;
}
.mobile-mode {
  display: none;
}
//////////Sizing/////////
.width1 {
  width: 1rem;
}
.width125 {
  width: 1.25rem;
}
.width15 {
  width: 1.5rem;
}
.width2 {
  width: 2rem;
}
.width25 {
  width: 2.5rem;
}
.h-90 {
  height: 90%;
}

.swiper-fluid {
  padding-left: 12px !important;
}
.scrollnone {
  scrollbar-width: none;
}
.fs-mid {
  font-size: 0.85rem;
}

.fs-small {
  font-size: 0.75rem;
}

.heading-text {
  font-size: 1rem;
}

.text-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.mr-2 {
  margin-right: 0.75rem;
}
.fs-8 {
  font-size: 0.8rem;
}
.fs-7 {
  font-size: 0.9rem;
}
.text-justify {
  text-align: justify;
}
.inputField {
  padding: 0.5rem 0.75rem;
  gap: 10px;
  border-radius: 0.25rem;
  border: 1px solid $grey;
  color: $grey;
  opacity: 0px;
}
.commonButton {
  padding: 0.25rem 1rem;
  border-radius: 5px;
  border: none;
  background-color: $primaryYellow;
  font-size: 1rem;
  font-weight: 600;
}
.greencommonButton {
  padding: 0.25rem 1rem;
  border-radius: 5px;
  border: none;
  background-color: var(--primaryGreen);
  font-size: 1rem;
  font-weight: 600;
}
.cPr {
  cursor: pointer;
}
.ls-1 {
  letter-spacing: 1px;
}
@media screen and (max-width: 767px) {
  .h-xs-100 {
    height: 100%;
  }

  .rounded-xs-0 {
    border-radius: 0px !important;
  }
}

.label-control {
  display: block;
  margin-bottom: 0.3rem;
}

.border-bottom-common {
  border-bottom: solid #f2f3f4;
}

.bottom-curve {
  border-radius: 0px 0px 16px 16px;
}

.top-curve {
  border-radius: 16px 16px 0px 0px;
}
@media screen and (max-width: 989px) {
  .borderMob {
    border: none !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .h-md-100 {
    height: 75%;
  }
}
