@media screen and (max-width: 767px){
    .mobile-mode {
        display: block !important;
    }

    .non-mobile-mode {
        display: none !important;
    }
}

@media screen and (min-width: 768px){
    .mobile-mode {
        display: none !important;
    }

    .non-mobile-mode {
        display: block !important;
    }
}