
$main-body-color: #F5F5F5;
//Layout
$topbar-bg-color: #f7bd03;
$topbar-icon-bg-color: #FFFFFF;

$bottom-bg-color: #FFFFFF;
$topbar-icon-color: var(--header-icon-color);
$topbar-text-color: var(--header-text-color);
$greyBackground:#ededed;
$bottombar-text-color: #484C52;

//Common Colour
$white: #FFFFFF;
$lightGrey:#ddd;
$red:#ff0000;
$grey:#7f7f7f;
$background-grey:#d9d9d9;
$primaryYellow:#f7bd03;
$black : #000000;
:root{
    --black:#000;
    --darkGrey:#292929;
    --white:#fff;
    --lightGrey:#ddd;
    --red:#ff0000;
    --grey:#7f7f7f;
    --background-grey:#d9d9d9;
    --primaryYellow:#f7bd03;
    --primaryGreen:#05C9AB;
    --greenSuccessColor:#03a685
}