.widget-space {
    .widget-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
  
        .widget-title {
          display: -webkit-box;
          max-width: 80%;
          font-size: 0.85rem;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          padding-left: 0;
          justify-content: start;
          font-weight: 600;
          @media (min-width: 768px) and (max-width: 1199px) {
            font-size: 1rem;
          }
          @media (min-width: 1200px) {
            font-size: 1.2rem;
          }
        }

        .widget-link {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: 0.65rem;
            margin-right: 5px;
            text-transform: capitalize;
            @media (min-width: 768px) and (max-width: 1199px) {
              font-size: 0.75rem;
            }
            @media (min-width: 1200px) {
              font-size: 0.85rem;
            }
          }
        }
    }
}

.vendor-design {
  &.vendor-list {
    .vendor-design-inner {
      border-radius: 0.8rem;
      // background: $white;
      color: #fff;
      .vendor-left {
        flex-basis: 40%;
      }
      .vendor-right {
        flex-basis: 60%;
      }
    }
  }
  &.vendor-column {
    .vendor-design-inner {
      flex-direction: column;
      border-radius: 0.8rem;
      // background: $white;
      color: #fff;
      .vendor-left {
        flex-basis: 40%;
      }
      .vendor-right {
        flex-basis: 60%;
      }
    }
  }
}
.disabled-button {
  background-color: var(--lightGrey);
}
.tryAndBuy{
  width: 25%;
  border-radius: 0px 5px 0px 0px;
}
.product-design-container {
  background: none;
  border: 1px solid #fff;
  .product-thumbnail {

  }

  .product-det {
    .pro-brand {
      font-size: 0.9rem;
      margin-bottom: 0.2rem;
      display: block;
    }
    .pro-specs {
      .pro-title {
        margin-bottom: 0.3rem;
        font-weight: 500;
      }
      .pro-price {
        display: block;
        .pro-offer-price {
          font-weight: 600;
          margin-right: 0.3rem;
        }
        .pro-mrp-price {
          font-weight: 600;
          color: #8B8B8B;
        }
      }
    }
  }
}