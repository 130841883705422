.product-detail-container {
    .product-detail-inner-container {
        .product-details-body {
            padding-top: 1rem;
            .product-image-container {
                text-align: center;
                padding: 1rem;
            }

            .product-spec-container {
                .product-details-variants-container {
                    .product-variants-section {

                        .variant-values {
                            .ecom-variant-option {
                                border: 1px solid #DDDDDD;
                                &.selected {
                                    background: #000000;
                                    color: white;
                                }
                                &.disabled {
                                    opacity: 0.6;
                                }
                            }
                        }
                    }
                }
            }
        }

        .product-details-footer-container {
            .product-action-cta {
                .add-to-cart-btn {
                    &[disabled]{
                        opacity: 0.5;
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
}

.product-thumb-img {
    height: 35rem; 
    max-width: 100%;
    object-fit: cover;
    width: auto;
}

.vendor-detail-container {
    .vendor-inner-container {
        .vendor-header-comp {
            .vendor-img-sec {
                img {
                    height: 300px;
                    width: 100%;
                    object-fit: cover;
                    object-position: top;
                }
            }
            .vendor-spec-sec {
                .vendor-biz-name {
                    font-weight: 600;
                    font-size: 1.1rem;
                }
                .vendor-biz-distance {
                    // background: #F5F5F5;
                    border: 1px solid #fff;
                    padding: 0.4rem;
                    border-radius: 10px;
                    .vendor-biz-address {
                        display: flex;
                        align-items: center;
                        .marker-icon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }
        .vendor-product-list {
            .vendor-widget-header {
                padding: 0 15px;
            }
        }
    }
}