@import './shared/variables';

.navbar-bottom {
    background: var(--darkGrey);
    padding: 0.4rem 0;
    // border-radius: 1.25rem 1.25rem 0 0;
    .navbar-bottom-wrapper {
        flex-wrap: inherit;
        .navbar-bottom-menu {
            flex: 1 20%;
            max-width: 20%;
            .nav-cta {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                img {
                    height: 20px;
                    width: 20px;
                }
                span {
                    color: var(--white);
                }
            }
        }
    }
}

.navbar-top {
    background: #fff;
    padding: 0.4rem;
    // border-bottom: 2px solid #e7e7e7;
    &.navbar-size-height {
        height: 5rem;
    }
    .p-category{
        h6{
            font-weight: 600;
            letter-spacing: 1px;
            cursor: pointer;
        }
        
    }
    .nav-left {
        flex-basis: 60%;
        max-width: 60%;
        .nav-logo {
            margin-right: 0.6rem;
        }

        .nav-delivery-info {
            align-items: flex-end;
            .del-text {
                margin-right: 0.5rem;
                font-size: 0.9rem;
            }
        }
    }
    .nav-right {
        flex-basis: 40%;
        max-width: 40%;
        display: flex;
        justify-content: flex-end;
        &.nav-cta {
            span {
                // background: $topbar-icon-bg-color;
                cursor: pointer;
                height: 2.25rem;
                width: 2.25rem;
                display: flex;
                border-radius: 50%;
                justify-content: center;
                align-items: center;
                img {
                    height: 1.5rem;
                }
            }
            // span:hover{
            //     filter:drop-shadow(0px 0px 5px var(--primaryYellow)) ;
            // }
        }
        .searchBar{
            :focus-visible {
                outline: none!important;
            }
        }
    }
}

.is-loading-enabled {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    background: #05C9AB;
    z-index: 99999;
    left: 0;
    .is-loading-inner {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 100%;
        img {
            height: 75px;
        }
    }
}
@media screen and (max-width:967px) {
    .nav-right {
        flex-basis: 82%!important;
        max-width: 82%!important;
     }
     .nav-left {
        flex-basis: 15%!important;
        max-width: 15%!important;
      }
}